type LogoPropsType = {
  format: "mobile" | "desktop"
}

export const APDevLogo = ({format}: LogoPropsType): JSX.Element => {
  return (
    <div className="ap-dev-logo">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={format === "mobile" ? "160" : "200"}
        zoomAndPan="magnify"
        viewBox="0 0 150 37.5"
        height="50"
        preserveAspectRatio="xMidYMid meet"
        version="1.0"
      >
        <defs>
          <g />
        </defs>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(5.641608, 27.838406)">
            <g>
              <path d="M 14.84375 -2.78125 L 1.203125 -8.796875 L 1.203125 -10.765625 L 14.84375 -17.578125 L 14.84375 -14.28125 L 5.453125 -9.921875 L 14.84375 -6.0625 Z M 14.84375 -2.78125 " />
            </g>
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(21.708885, 27.838406)">
            <g />
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(29.013739, 27.838406)">
            <g />
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(36.318592, 27.838406)">
            <g />
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(43.623446, 27.838406)">
            <g />
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(50.9283, 27.838406)">
            <g />
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(58.233153, 27.838406)">
            <g />
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(65.538007, 27.838406)">
            <g />
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(72.842861, 27.838406)">
            <g />
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(80.147714, 27.838406)">
            <g />
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(87.452568, 27.838406)">
            <g />
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(94.757422, 27.838406)">
            <g />
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(102.062275, 27.838406)">
            <g />
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(109.367129, 27.838406)">
            <g />
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(116.663542, 27.838406)">
            <g>
              <path d="M 11.484375 -20.078125 L 4 0 L 0.1875 0 L 7.671875 -20.078125 Z M 11.484375 -20.078125 " />
            </g>
          </g>
        </g>
        <g fill="#ffffff" fillOpacity="1">
          <g transform="translate(128.279907, 27.838406)">
            <g>
              <path d="M 1.203125 -6.0625 L 10.609375 -9.921875 L 1.203125 -14.28125 L 1.203125 -17.578125 L 14.84375 -10.765625 L 14.84375 -8.796875 L 1.203125 -2.78125 Z M 1.203125 -6.0625 " />
            </g>
          </g>
        </g>
        <g fill="#ffbd59" fillOpacity="1">
          <g transform="translate(24.067064, 27.532835)">
            <g>
              <path d="M 14.8125 0 L 13.359375 -4.78125 L 6.046875 -4.78125 L 4.59375 0 L 0 0 L 7.09375 -20.15625 L 12.296875 -20.15625 L 19.40625 0 Z M 12.34375 -8.34375 L 10.890625 -13.015625 C 10.796875 -13.328125 10.671875 -13.726562 10.515625 -14.21875 C 10.367188 -14.707031 10.21875 -15.207031 10.0625 -15.71875 C 9.914062 -16.226562 9.796875 -16.671875 9.703125 -17.046875 C 9.617188 -16.671875 9.492188 -16.203125 9.328125 -15.640625 C 9.171875 -15.085938 9.019531 -14.5625 8.875 -14.0625 C 8.726562 -13.570312 8.625 -13.222656 8.5625 -13.015625 L 7.109375 -8.34375 Z M 12.34375 -8.34375 " />
            </g>
          </g>
          <g transform="translate(43.471665, 27.532835)">
            <g>
              <path d="M 8.9375 -20.078125 C 11.53125 -20.078125 13.421875 -19.519531 14.609375 -18.40625 C 15.796875 -17.289062 16.390625 -15.757812 16.390625 -13.8125 C 16.390625 -12.9375 16.253906 -12.097656 15.984375 -11.296875 C 15.722656 -10.492188 15.289062 -9.78125 14.6875 -9.15625 C 14.09375 -8.53125 13.296875 -8.035156 12.296875 -7.671875 C 11.304688 -7.316406 10.078125 -7.140625 8.609375 -7.140625 L 6.78125 -7.140625 L 6.78125 0 L 2.53125 0 L 2.53125 -20.078125 Z M 8.71875 -16.59375 L 6.78125 -16.59375 L 6.78125 -10.625 L 8.1875 -10.625 C 8.976562 -10.625 9.664062 -10.726562 10.25 -10.9375 C 10.84375 -11.15625 11.296875 -11.488281 11.609375 -11.9375 C 11.929688 -12.382812 12.09375 -12.960938 12.09375 -13.671875 C 12.09375 -14.648438 11.816406 -15.378906 11.265625 -15.859375 C 10.722656 -16.347656 9.875 -16.59375 8.71875 -16.59375 Z M 8.71875 -16.59375 " />
            </g>
          </g>
        </g>
        <g fill="#5ce1e6" fillOpacity="1">
          <g transform="translate(61.131464, 27.532835)">
            <g>
              <path d="M 19.1875 -10.234375 C 19.1875 -7.972656 18.753906 -6.082031 17.890625 -4.5625 C 17.023438 -3.050781 15.769531 -1.910156 14.125 -1.140625 C 12.488281 -0.378906 10.519531 0 8.21875 0 L 2.53125 0 L 2.53125 -20.078125 L 8.828125 -20.078125 C 10.929688 -20.078125 12.753906 -19.703125 14.296875 -18.953125 C 15.847656 -18.210938 17.050781 -17.109375 17.90625 -15.640625 C 18.757812 -14.179688 19.1875 -12.378906 19.1875 -10.234375 Z M 14.765625 -10.125 C 14.765625 -11.601562 14.546875 -12.820312 14.109375 -13.78125 C 13.671875 -14.738281 13.03125 -15.445312 12.1875 -15.90625 C 11.34375 -16.363281 10.296875 -16.59375 9.046875 -16.59375 L 6.78125 -16.59375 L 6.78125 -3.515625 L 8.609375 -3.515625 C 10.691406 -3.515625 12.238281 -4.066406 13.25 -5.171875 C 14.257812 -6.285156 14.765625 -7.9375 14.765625 -10.125 Z M 14.765625 -10.125 " />
            </g>
          </g>
          <g transform="translate(81.950555, 27.532835)">
            <g>
              <path d="M 8.53125 -15.640625 C 9.945312 -15.640625 11.164062 -15.363281 12.1875 -14.8125 C 13.21875 -14.269531 14.007812 -13.484375 14.5625 -12.453125 C 15.125 -11.421875 15.40625 -10.160156 15.40625 -8.671875 L 15.40625 -6.640625 L 5.5 -6.640625 C 5.550781 -5.453125 5.90625 -4.519531 6.5625 -3.84375 C 7.226562 -3.175781 8.15625 -2.84375 9.34375 -2.84375 C 10.320312 -2.84375 11.21875 -2.941406 12.03125 -3.140625 C 12.84375 -3.347656 13.679688 -3.648438 14.546875 -4.046875 L 14.546875 -0.8125 C 13.785156 -0.4375 12.988281 -0.160156 12.15625 0.015625 C 11.332031 0.191406 10.328125 0.28125 9.140625 0.28125 C 7.609375 0.28125 6.25 0 5.0625 -0.5625 C 3.875 -1.132812 2.941406 -2.003906 2.265625 -3.171875 C 1.597656 -4.335938 1.265625 -5.800781 1.265625 -7.5625 C 1.265625 -9.363281 1.566406 -10.859375 2.171875 -12.046875 C 2.785156 -13.242188 3.632812 -14.140625 4.71875 -14.734375 C 5.8125 -15.335938 7.082031 -15.640625 8.53125 -15.640625 Z M 8.5625 -12.65625 C 7.738281 -12.65625 7.0625 -12.394531 6.53125 -11.875 C 6 -11.351562 5.691406 -10.535156 5.609375 -9.421875 L 11.484375 -9.421875 C 11.472656 -10.046875 11.359375 -10.597656 11.140625 -11.078125 C 10.929688 -11.566406 10.613281 -11.953125 10.1875 -12.234375 C 9.757812 -12.515625 9.21875 -12.65625 8.5625 -12.65625 Z M 8.5625 -12.65625 " />
            </g>
          </g>
          <g transform="translate(98.567376, 27.532835)">
            <g>
              <path d="M 5.84375 0 L 0 -15.359375 L 4.375 -15.359375 L 7.34375 -6.609375 C 7.507812 -6.097656 7.640625 -5.554688 7.734375 -4.984375 C 7.828125 -4.421875 7.894531 -3.910156 7.9375 -3.453125 L 8.046875 -3.453125 C 8.078125 -3.941406 8.144531 -4.460938 8.25 -5.015625 C 8.351562 -5.566406 8.492188 -6.097656 8.671875 -6.609375 L 11.625 -15.359375 L 16 -15.359375 L 10.15625 0 Z M 5.84375 0 " />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};
