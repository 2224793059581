type LogoPropsType = {
  className: "cwf-logo-project" | "cwf-logo-portfolio";
}

export const CWFLogo = ({className}: LogoPropsType): JSX.Element => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      zoomAndPan="magnify"
      viewBox="0 0 375 337.499995"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <g />
        <clipPath id="28ff6c1513">
          <path
            d="M 8 26 L 229 26 L 229 300 L 8 300 Z M 8 26 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="c596dd9812">
          <path
            d="M 4.335938 74.578125 L 150.996094 22.089844 L 233.003906 251.25 L 86.34375 303.734375 Z M 4.335938 74.578125 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="5b2aaf96cf">
          <path
            d="M 5 21 L 232 21 L 232 304 L 5 304 Z M 5 21 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="cf3ed4d325">
          <path
            d="M 0.277344 71.078125 L 151.128906 16.964844 L 236.34375 254.519531 L 85.492188 308.632812 Z M 0.277344 71.078125 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="b28223c467">
          <path
            d="M 135 32 L 369 32 L 369 317 L 135 317 Z M 135 32 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="ba1885abfb">
          <path
            d="M 218.199219 28.355469 L 372.730469 85.492188 L 285.984375 320.097656 L 131.453125 262.960938 Z M 218.199219 28.355469 "
            clipRule="nonzero"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#28ff6c1513)">
        <g clipPath="url(#c596dd9812)">
          <path
            fill="#ffffff"
            d="M 218.8125 256.328125 L 100.53125 298.65625 C 92.714844 301.453125 84.066406 297.367188 81.269531 289.554688 L 9.410156 88.753906 C 6.613281 80.941406 10.707031 72.296875 18.523438 69.5 L 136.804688 27.167969 C 144.625 24.371094 153.273438 28.457031 156.066406 36.269531 L 227.929688 237.070312 C 230.726562 244.882812 226.632812 253.527344 218.8125 256.328125 "
            fillOpacity="1"
            fillRule="nonzero"
          />
        </g>
      </g>
      <g clipPath="url(#5b2aaf96cf)">
        <g clipPath="url(#cf3ed4d325)">
          <path
            fill="#222222"
            d="M 137.371094 30.90625 C 142.121094 29.203125 147.421875 31.710938 149.128906 36.464844 L 222.402344 240.738281 C 224.109375 245.496094 221.609375 250.796875 216.859375 252.503906 L 99.25 294.691406 C 94.5 296.394531 89.199219 293.886719 87.496094 289.132812 L 14.21875 84.855469 C 12.511719 80.101562 15.011719 74.796875 19.761719 73.09375 L 137.371094 30.90625 M 134.507812 22.925781 L 16.898438 65.113281 C 7.742188 68.398438 2.957031 78.546875 6.246094 87.714844 L 79.523438 291.992188 C 82.8125 301.160156 92.953125 305.957031 102.113281 302.671875 L 219.722656 260.480469 C 228.878906 257.195312 233.664062 247.050781 230.375 237.878906 L 157.097656 33.605469 C 153.808594 24.4375 143.667969 19.640625 134.507812 22.925781 "
            fillOpacity="1"
            fillRule="nonzero"
          />
        </g>
      </g>
      <g clipPath="url(#b28223c467)">
        <g clipPath="url(#ba1885abfb)">
          <path
            fill="#000000"
            d="M 272.839844 315.238281 L 144.59375 267.820312 C 137.355469 265.140625 133.632812 257.066406 136.304688 249.835938 L 213.347656 41.476562 C 216.019531 34.246094 224.101562 30.535156 231.339844 33.214844 L 359.585938 80.632812 C 366.828125 83.3125 370.550781 91.386719 367.875 98.617188 L 290.835938 306.976562 C 288.160156 314.207031 280.082031 317.917969 272.839844 315.238281 "
            fillOpacity="1"
            fillRule="nonzero"
          />
        </g>
      </g>
      <g fill="#ffffff" fillOpacity="1">
        <g transform="translate(221.512593, 124.519492)">
          <g>
            <path d="M 19.734375 7.484375 L 20.875 -0.28125 L 22.171875 -10.109375 L 22.03125 -10.15625 L 12.1875 4.609375 L 6.1875 2.34375 L 8.109375 -20.3125 L 13.6875 -18.203125 L 12.59375 -8.25 C 12.363281 -6.476562 11.90625 -4.097656 11.21875 -1.109375 L 11.34375 -1.0625 C 11.726562 -1.976562 12.488281 -3.367188 13.625 -5.234375 L 14.515625 -6.6875 L 20.75 -15.53125 L 26.90625 -13.203125 L 25.578125 -2.5 C 25.523438 -2.207031 25.453125 -1.773438 25.359375 -1.203125 C 25.265625 -0.640625 25.148438 -0.046875 25.015625 0.578125 C 24.890625 1.210938 24.757812 1.820312 24.625 2.40625 C 24.488281 3 24.367188 3.472656 24.265625 3.828125 L 24.375 3.875 C 24.8125 3.039062 25.457031 1.910156 26.3125 0.484375 C 27.175781 -0.941406 27.769531 -1.894531 28.09375 -2.375 L 33.984375 -10.515625 L 39.453125 -8.4375 L 25.8125 9.78125 Z M 19.734375 7.484375 " />
          </g>
        </g>
      </g>
      <g fill="#ffffff" fillOpacity="1">
        <g transform="translate(253.579133, 136.666534)">
          <g>
            <path d="M 12.4375 -24.71875 C 13.125 -26.539062 14.484375 -27.066406 16.515625 -26.296875 C 18.535156 -25.523438 19.203125 -24.226562 18.515625 -22.40625 C 18.191406 -21.550781 17.6875 -20.976562 17 -20.6875 C 16.3125 -20.394531 15.460938 -20.441406 14.453125 -20.828125 C 12.421875 -21.597656 11.75 -22.894531 12.4375 -24.71875 Z M 8.5 3.21875 L 2.921875 1.109375 L 10.671875 -19.34375 L 16.25 -17.234375 Z M 8.5 3.21875 " />
          </g>
        </g>
      </g>
      <g fill="#ffffff" fillOpacity="1">
        <g transform="translate(265.011877, 140.997342)">
          <g>
            <path d="M 13.09375 0.296875 C 14.0625 0.660156 15.304688 0.890625 16.828125 0.984375 L 15.265625 5.140625 C 13.640625 5.234375 11.816406 4.894531 9.796875 4.125 C 7.566406 3.28125 6.15625 2.097656 5.5625 0.578125 C 4.96875 -0.929688 5.097656 -2.8125 5.953125 -5.0625 L 9.6875 -14.921875 L 7.015625 -15.9375 L 7.90625 -18.28125 L 11.703125 -19 L 14.921875 -22.71875 L 18.5 -21.359375 L 16.859375 -17 L 22.578125 -14.828125 L 20.984375 -10.640625 L 15.265625 -12.8125 L 11.53125 -2.953125 C 11.226562 -2.160156 11.226562 -1.488281 11.53125 -0.9375 C 11.84375 -0.394531 12.363281 0.015625 13.09375 0.296875 Z M 13.09375 0.296875 " />
          </g>
        </g>
      </g>
      <g fill="#ffffff" fillOpacity="1">
        <g transform="translate(281.273808, 147.157482)">
          <g>
            <path d="M 21.796875 8.265625 L 16.21875 6.140625 L 20.75 -5.8125 C 21.863281 -8.75 21.328125 -10.632812 19.140625 -11.46875 C 17.578125 -12.050781 16.242188 -11.945312 15.140625 -11.15625 C 14.046875 -10.363281 13.046875 -8.78125 12.140625 -6.40625 L 8.5 3.21875 L 2.921875 1.109375 L 13.703125 -27.359375 L 19.28125 -25.25 L 17.078125 -19.4375 C 16.910156 -18.988281 16.46875 -17.945312 15.75 -16.3125 L 15.015625 -14.71875 L 15.3125 -14.609375 C 17.300781 -16.140625 19.648438 -16.390625 22.359375 -15.359375 C 24.765625 -14.453125 26.34375 -13.113281 27.09375 -11.34375 C 27.84375 -9.582031 27.757812 -7.492188 26.84375 -5.078125 Z M 21.796875 8.265625 " />
          </g>
        </g>
      </g>
      <g fill="#ffffff" fillOpacity="1">
        <g transform="translate(175.047456, 166.115099)">
          <g>
            <path d="M 8.9375 3.390625 L 3.359375 1.265625 L 13.484375 -25.46875 L 28.8125 -19.65625 L 27.0625 -15.015625 L 17.3125 -18.703125 L 14.6875 -11.8125 L 23.765625 -8.375 L 22.015625 -3.75 L 12.9375 -7.1875 Z M 8.9375 3.390625 " />
          </g>
        </g>
      </g>
      <g fill="#ffffff" fillOpacity="1">
        <g transform="translate(195.608083, 173.903616)">
          <g>
            <path d="M 22.234375 -15.40625 C 22.984375 -15.125 23.585938 -14.832031 24.046875 -14.53125 L 21.65625 -9.453125 C 21.25 -9.742188 20.722656 -10.015625 20.078125 -10.265625 C 18.296875 -10.941406 16.734375 -11.007812 15.390625 -10.46875 C 14.046875 -9.925781 13.0625 -8.832031 12.4375 -7.1875 L 8.5 3.21875 L 2.921875 1.109375 L 10.671875 -19.34375 L 14.90625 -17.734375 L 14.421875 -14 L 14.6875 -13.890625 C 15.757812 -14.796875 16.96875 -15.394531 18.3125 -15.6875 C 19.65625 -15.988281 20.960938 -15.894531 22.234375 -15.40625 Z M 22.234375 -15.40625 " />
          </g>
        </g>
      </g>
      <g fill="#ffffff" fillOpacity="1">
        <g transform="translate(212.61999, 180.347852)">
          <g>
            <path d="M 12.4375 -24.71875 C 13.125 -26.539062 14.484375 -27.066406 16.515625 -26.296875 C 18.535156 -25.523438 19.203125 -24.226562 18.515625 -22.40625 C 18.191406 -21.550781 17.6875 -20.976562 17 -20.6875 C 16.3125 -20.394531 15.460938 -20.441406 14.453125 -20.828125 C 12.421875 -21.597656 11.75 -22.894531 12.4375 -24.71875 Z M 8.5 3.21875 L 2.921875 1.109375 L 10.671875 -19.34375 L 16.25 -17.234375 Z M 8.5 3.21875 " />
          </g>
        </g>
      </g>
      <g fill="#ffffff" fillOpacity="1">
        <g transform="translate(224.052734, 184.67866)">
          <g>
            <path d="M 17.78125 -12.546875 C 16.601562 -12.992188 15.535156 -12.96875 14.578125 -12.46875 C 13.617188 -11.96875 12.832031 -11.050781 12.21875 -9.71875 L 20.046875 -6.75 C 20.535156 -8.132812 20.578125 -9.332031 20.171875 -10.34375 C 19.765625 -11.363281 18.96875 -12.097656 17.78125 -12.546875 Z M 12.046875 4.96875 C 8.753906 3.726562 6.523438 1.851562 5.359375 -0.65625 C 4.191406 -3.175781 4.238281 -6.101562 5.5 -9.4375 C 6.800781 -12.863281 8.660156 -15.191406 11.078125 -16.421875 C 13.503906 -17.648438 16.226562 -17.691406 19.25 -16.546875 C 22.132812 -15.453125 24.070312 -13.769531 25.0625 -11.5 C 26.050781 -9.238281 26 -6.660156 24.90625 -3.765625 L 23.875 -1.0625 L 10.671875 -6.0625 C 10.140625 -4.445312 10.144531 -3.023438 10.6875 -1.796875 C 11.226562 -0.578125 12.289062 0.328125 13.875 0.921875 C 15.113281 1.390625 16.328125 1.703125 17.515625 1.859375 C 18.710938 2.015625 20.015625 2.046875 21.421875 1.953125 L 19.78125 6.265625 C 18.625 6.378906 17.441406 6.34375 16.234375 6.15625 C 15.035156 5.976562 13.640625 5.582031 12.046875 4.96875 Z M 12.046875 4.96875 " />
          </g>
        </g>
      </g>
      <g fill="#ffffff" fillOpacity="1">
        <g transform="translate(246.186505, 193.063096)">
          <g>
            <path d="M 21.796875 8.265625 L 16.21875 6.140625 L 20.75 -5.8125 C 21.300781 -7.28125 21.457031 -8.476562 21.21875 -9.40625 C 20.976562 -10.34375 20.285156 -11.03125 19.140625 -11.46875 C 17.578125 -12.050781 16.25 -11.953125 15.15625 -11.171875 C 14.0625 -10.398438 13.054688 -8.8125 12.140625 -6.40625 L 8.5 3.21875 L 2.921875 1.109375 L 10.671875 -19.34375 L 14.9375 -17.734375 L 14.703125 -14.84375 L 15.015625 -14.71875 C 15.992188 -15.46875 17.128906 -15.890625 18.421875 -15.984375 C 19.710938 -16.078125 21.046875 -15.863281 22.421875 -15.34375 C 24.804688 -14.4375 26.367188 -13.101562 27.109375 -11.34375 C 27.859375 -9.59375 27.769531 -7.503906 26.84375 -5.078125 Z M 21.796875 8.265625 " />
          </g>
        </g>
      </g>
      <g fill="#ffffff" fillOpacity="1">
        <g transform="translate(270.808039, 202.389914)">
          <g>
            <path d="M 9.265625 3.921875 C 6.859375 3.015625 5.320312 1.367188 4.65625 -1.015625 C 4 -3.398438 4.296875 -6.242188 5.546875 -9.546875 C 6.816406 -12.898438 8.503906 -15.25 10.609375 -16.59375 C 12.710938 -17.945312 14.992188 -18.15625 17.453125 -17.21875 C 20.015625 -16.25 21.597656 -14.507812 22.203125 -12 L 22.390625 -11.921875 C 22.679688 -13.554688 23.050781 -14.972656 23.5 -16.171875 L 25.984375 -22.71875 L 31.578125 -20.59375 L 20.796875 7.875 L 16.53125 6.265625 L 16.4375 3.1875 L 16.203125 3.09375 C 14.171875 4.625 11.859375 4.898438 9.265625 3.921875 Z M 12.90625 0.21875 C 14.332031 0.757812 15.53125 0.738281 16.5 0.15625 C 17.476562 -0.414062 18.382812 -1.6875 19.21875 -3.65625 L 19.4375 -4.25 C 20.269531 -6.4375 20.523438 -8.132812 20.203125 -9.34375 C 19.890625 -10.550781 18.96875 -11.445312 17.4375 -12.03125 C 16.195312 -12.5 15.03125 -12.335938 13.9375 -11.546875 C 12.84375 -10.753906 11.914062 -9.347656 11.15625 -7.328125 C 10.394531 -5.316406 10.171875 -3.675781 10.484375 -2.40625 C 10.804688 -1.144531 11.613281 -0.269531 12.90625 0.21875 Z M 12.90625 0.21875 " />
          </g>
        </g>
      </g>
      <g fill="#ffffff" fillOpacity="1">
        <g transform="translate(294.514954, 211.370269)">
          <g>
            <path d="M 19.5 0.4375 C 18.707031 2.539062 17.375 3.863281 15.5 4.40625 C 13.625 4.945312 11.234375 4.664062 8.328125 3.5625 C 6.835938 3 5.609375 2.421875 4.640625 1.828125 C 3.671875 1.234375 2.800781 0.570312 2.03125 -0.15625 L 3.78125 -4.78125 C 4.625 -3.90625 5.632812 -3.054688 6.8125 -2.234375 C 7.988281 -1.410156 9.070312 -0.8125 10.0625 -0.4375 C 12.082031 0.320312 13.3125 0.117188 13.75 -1.046875 C 13.914062 -1.484375 13.921875 -1.890625 13.765625 -2.265625 C 13.609375 -2.640625 13.265625 -3.125 12.734375 -3.71875 C 12.203125 -4.320312 11.476562 -5.0625 10.5625 -5.9375 C 9.238281 -7.1875 8.3125 -8.234375 7.78125 -9.078125 C 7.25 -9.921875 6.953125 -10.765625 6.890625 -11.609375 C 6.835938 -12.460938 7.015625 -13.421875 7.421875 -14.484375 C 8.109375 -16.304688 9.34375 -17.445312 11.125 -17.90625 C 12.90625 -18.375 15.09375 -18.117188 17.6875 -17.140625 C 20.144531 -16.203125 22.335938 -14.753906 24.265625 -12.796875 L 21.046875 -9.40625 C 20.179688 -10.238281 19.359375 -10.960938 18.578125 -11.578125 C 17.804688 -12.191406 16.957031 -12.675781 16.03125 -13.03125 C 14.382812 -13.65625 13.394531 -13.523438 13.0625 -12.640625 C 12.863281 -12.128906 12.957031 -11.585938 13.34375 -11.015625 C 13.738281 -10.453125 14.691406 -9.472656 16.203125 -8.078125 C 17.554688 -6.828125 18.5 -5.78125 19.03125 -4.9375 C 19.5625 -4.101562 19.867188 -3.25 19.953125 -2.375 C 20.035156 -1.507812 19.882812 -0.570312 19.5 0.4375 Z M 19.5 0.4375 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(51.961123, 142.269113)">
          <g>
            <path d="M 6.609375 -27.90625 C 4.472656 -27.132812 3.109375 -25.722656 2.515625 -23.671875 C 1.921875 -21.628906 2.144531 -19.164062 3.1875 -16.28125 C 5.363281 -10.289062 8.703125 -8.113281 13.203125 -9.75 C 15.097656 -10.4375 17.210938 -11.738281 19.546875 -13.65625 L 21.28125 -8.890625 C 19.414062 -7.171875 17.128906 -5.816406 14.421875 -4.828125 C 10.503906 -3.398438 7.078125 -3.492188 4.140625 -5.109375 C 1.203125 -6.722656 -1.070312 -9.753906 -2.6875 -14.203125 C -3.695312 -16.992188 -4.070312 -19.625 -3.8125 -22.09375 C -3.5625 -24.570312 -2.6875 -26.71875 -1.1875 -28.53125 C 0.300781 -30.34375 2.328125 -31.710938 4.890625 -32.640625 C 7.492188 -33.585938 10.347656 -33.910156 13.453125 -33.609375 L 13.296875 -28.296875 C 12.117188 -28.421875 10.953125 -28.472656 9.796875 -28.453125 C 8.648438 -28.441406 7.585938 -28.257812 6.609375 -27.90625 Z M 6.609375 -27.90625 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(75.952538, 133.549361)">
          <g>
            <path d="M 15.984375 -5.8125 L 13.890625 -8.21875 L 13.75 -8.15625 C 13.226562 -6.625 12.554688 -5.445312 11.734375 -4.625 C 10.910156 -3.8125 9.691406 -3.113281 8.078125 -2.53125 C 6.109375 -1.8125 4.351562 -1.804688 2.8125 -2.515625 C 1.269531 -3.234375 0.117188 -4.632812 -0.640625 -6.71875 C -1.429688 -8.894531 -1.253906 -10.78125 -0.109375 -12.375 C 1.035156 -13.96875 3.128906 -15.378906 6.171875 -16.609375 L 9.6875 -18.015625 L 9.359375 -18.921875 C 8.609375 -21.003906 7.164062 -21.65625 5.03125 -20.875 C 3.382812 -20.28125 1.628906 -19.078125 -0.234375 -17.265625 L -3.453125 -20.390625 C -1.546875 -22.484375 0.847656 -24.050781 3.734375 -25.09375 C 6.492188 -26.09375 8.828125 -26.257812 10.734375 -25.59375 C 12.640625 -24.9375 14.035156 -23.382812 14.921875 -20.9375 L 19.90625 -7.234375 Z M 10.875 -14.75 L 8.734375 -13.875 C 7.117188 -13.226562 6.015625 -12.488281 5.421875 -11.65625 C 4.828125 -10.832031 4.726562 -9.875 5.125 -8.78125 C 5.695312 -7.195312 6.890625 -6.734375 8.703125 -7.390625 C 10.003906 -7.859375 10.90625 -8.609375 11.40625 -9.640625 C 11.914062 -10.679688 11.945312 -11.816406 11.5 -13.046875 Z M 10.875 -14.75 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(98.693826, 125.283971)">
          <g>
            <path d="M 6.8125 -26.171875 C 7.5625 -26.453125 8.207031 -26.628906 8.75 -26.703125 L 10.25 -21.296875 C 9.75 -21.253906 9.175781 -21.113281 8.53125 -20.875 C 6.738281 -20.21875 5.507812 -19.25 4.84375 -17.96875 C 4.175781 -16.6875 4.144531 -15.21875 4.75 -13.5625 L 8.546875 -3.109375 L 2.9375 -1.0625 L -4.53125 -21.625 L -0.265625 -23.171875 L 1.796875 -20.015625 L 2.078125 -20.109375 C 2.296875 -21.492188 2.816406 -22.734375 3.640625 -23.828125 C 4.472656 -24.929688 5.53125 -25.710938 6.8125 -26.171875 Z M 6.8125 -26.171875 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(115.791148, 119.069897)">
          <g>
            <path d="M 9.59375 -3.078125 C 7.175781 -2.203125 4.9375 -2.445312 2.875 -3.8125 C 0.8125 -5.175781 -0.820312 -7.519531 -2.03125 -10.84375 C -3.257812 -14.21875 -3.507812 -17.097656 -2.78125 -19.484375 C -2.0625 -21.878906 -0.46875 -23.523438 2 -24.421875 C 4.582031 -25.367188 6.914062 -25.085938 9 -23.578125 L 9.1875 -23.640625 C 8.34375 -25.054688 7.703125 -26.367188 7.265625 -27.578125 L 4.875 -34.15625 L 10.5 -36.203125 L 20.890625 -7.59375 L 16.609375 -6.03125 L 14.546875 -8.3125 L 14.296875 -8.21875 C 13.765625 -5.738281 12.195312 -4.023438 9.59375 -3.078125 Z M 9.921875 -8.234375 C 11.359375 -8.765625 12.257812 -9.566406 12.625 -10.640625 C 12.988281 -11.710938 12.84375 -13.265625 12.1875 -15.296875 L 11.96875 -15.890625 C 11.164062 -18.097656 10.25 -19.554688 9.21875 -20.265625 C 8.1875 -20.972656 6.90625 -21.046875 5.375 -20.484375 C 4.125 -20.035156 3.34375 -19.15625 3.03125 -17.84375 C 2.726562 -16.53125 2.945312 -14.859375 3.6875 -12.828125 C 4.414062 -10.816406 5.3125 -9.429688 6.375 -8.671875 C 7.445312 -7.910156 8.628906 -7.765625 9.921875 -8.234375 Z M 9.921875 -8.234375 " />
          </g>
        </g>
      </g>
      <g fill="#000000" fillOpacity="1">
        <g transform="translate(139.617093, 110.410285)">
          <g>
            <path d="M 15.0625 -12.390625 C 15.832031 -10.273438 15.6875 -8.398438 14.625 -6.765625 C 13.5625 -5.140625 11.570312 -3.796875 8.65625 -2.734375 C 7.15625 -2.191406 5.84375 -1.828125 4.71875 -1.640625 C 3.59375 -1.453125 2.5 -1.390625 1.4375 -1.453125 L -0.25 -6.09375 C 0.96875 -5.988281 2.285156 -6.003906 3.703125 -6.140625 C 5.128906 -6.285156 6.335938 -6.535156 7.328125 -6.890625 C 9.367188 -7.628906 10.175781 -8.582031 9.75 -9.75 C 9.59375 -10.195312 9.328125 -10.507812 8.953125 -10.6875 C 8.578125 -10.863281 7.992188 -11.003906 7.203125 -11.109375 C 6.421875 -11.222656 5.394531 -11.316406 4.125 -11.390625 C 2.3125 -11.472656 0.929688 -11.660156 -0.015625 -11.953125 C -0.960938 -12.242188 -1.738281 -12.691406 -2.34375 -13.296875 C -2.945312 -13.898438 -3.445312 -14.738281 -3.84375 -15.8125 C -4.5 -17.632812 -4.300781 -19.300781 -3.25 -20.8125 C -2.195312 -22.332031 -0.367188 -23.566406 2.234375 -24.515625 C 4.703125 -25.410156 7.300781 -25.75 10.03125 -25.53125 L 9.828125 -20.859375 C 8.617188 -20.921875 7.519531 -20.929688 6.53125 -20.890625 C 5.539062 -20.847656 4.582031 -20.660156 3.65625 -20.328125 C 2 -19.722656 1.332031 -18.976562 1.65625 -18.09375 C 1.832031 -17.582031 2.253906 -17.238281 2.921875 -17.0625 C 3.585938 -16.882812 4.953125 -16.765625 7.015625 -16.703125 C 8.859375 -16.640625 10.253906 -16.460938 11.203125 -16.171875 C 12.160156 -15.878906 12.945312 -15.429688 13.5625 -14.828125 C 14.1875 -14.222656 14.6875 -13.410156 15.0625 -12.390625 Z M 15.0625 -12.390625 " />
          </g>
        </g>
      </g>
    </svg>
  );
};
